import { graphql, PageProps } from 'gatsby'
import Seo from '../../components/seo'
import MarkdownPage from '../../components/MarkdownPage/MarkdownPage'
import '../../scss/common/what-is-page.scss'
import defaultMetadata from '../../content/home_page'

const WhatIsQMLPage = ({ data }: PageProps<Queries.WhatIsQMLPageQuery>) => {
  return (
    <MarkdownPage
      title={data.markdownRemark?.frontmatter?.title || ''}
      tableOfContents={data.markdownRemark?.tableOfContents || ''}
      html={data.markdownRemark?.html || ''}
      addClass="WhatIsPage"
    />
  )
}

export const Head = ({ data }: PageProps<Queries.WhatIsQMLPageQuery>) => {
  return (
    <Seo
      title={
        data.markdownRemark?.frontmatter?.meta_title ||
        defaultMetadata.meta_title
      }
      description={
        data.markdownRemark?.frontmatter?.meta_description ||
        defaultMetadata.meta_description
      }
      image={data.markdownRemark?.frontmatter?.meta_image || ''}
    />
  )
}

export default WhatIsQMLPage

export const pageQuery = graphql`
  query WhatIsQMLPage {
    markdownRemark(frontmatter: { slug: { eq: "/qml/whatisqml" } }) {
      frontmatter {
        slug
        title
        meta_title
        meta_description
        meta_image
      }
      tableOfContents
      html
    }
  }
`
